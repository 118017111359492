import { HeadCell } from '../../../../types';
import { UnitHeadCells } from '../../../types';

const unitHeadCells: readonly HeadCell<UnitHeadCells>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'English',
  },
  {
    id: 'nameDe',
    numeric: false,
    disablePadding: false,
    label: 'German',
  },
  {
    id: 'namePlural',
    numeric: false,
    disablePadding: false,
    label: 'English',
  },
  {
    id: 'nameDePlural',
    numeric: false,
    disablePadding: false,
    label: 'German',
  },
];

export default unitHeadCells;
