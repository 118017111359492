import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSearch } from '../../../../context';
import { fetchConfig } from '../../../../shared';
import { useDebounceValue } from '../../../../shared/hooks';
import { queries } from '../../../queries';
import CategoriesEnhancedTable from './categories-enhanced-table';

const CategoriesTable: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = parseInt(searchParams.get('page') || '1', 10);
  const perPageParam = parseInt(searchParams.get('perPage') || '50', 10);
  const { query } = useSearch();
  const debouncedQuery = useDebounceValue(query);

  const { data } = useQuery({
    ...queries.categories.filter({
      query: debouncedQuery,
      perPage: perPageParam,
      page: pageParam,
    }),
    keepPreviousData: true,
    suspense: true,
  });

  useEffect(() => {
    if (debouncedQuery === '') return;

    setSearchParams({ page: String(1), perPage: perPageParam.toString() });
  }, [debouncedQuery, perPageParam, setSearchParams]);

  if (!data) return null;

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchParams({ page: String(newPage + 1), perPage: perPageParam.toString() });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fetchConfig.rowsPerPageOptions.includes(Number(event.target.value))) {
      setSearchParams({ page: '1', perPage: event.target.value });
      return;
    }

    setSearchParams({ page: '1', perPage: '50' });
  };

  return (
    <CategoriesEnhancedTable
      data={data}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default CategoriesTable;
