import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useRecipesFilter } from '../../../../context';
import { fetchConfig, useDebounceValue } from '../../../../shared';
import { queries } from '../../../queries';
import { mapRecipeResponseToObject } from './mappers';
import RecipesTableView from './recipes-table-view';

const RecipesTable: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageParam = parseInt(searchParams.get('page') || '1', 10);
  const perPageParam = parseInt(searchParams.get('perPage') || '50', 10);
  const { query: filterQuery, data: filterData } = useRecipesFilter();
  const { category, complexity, cookingStart, cookingEnd, mealType, state } = filterData;
  const debouncedQuery = useDebounceValue(filterQuery);

  const { data } = useQuery({
    ...queries.recipes.filter({
      query: debouncedQuery,
      perPage: perPageParam,
      page: pageParam,
      complexity: complexity?.join(','),
      category: category?.join(','),
      mealType: mealType?.join(','),
      state,
      cookingStart,
      cookingEnd,
    }),
    keepPreviousData: true,
    refetchOnMount: true,
    refetchOnReconnect: true,
    suspense: true,
  });

  useEffect(() => {
    if (debouncedQuery === '') return;

    setSearchParams({ page: String(1), perPage: perPageParam.toString() });
  }, [debouncedQuery, perPageParam, setSearchParams]);

  if (!data) return null;

  const recipesResponse = mapRecipeResponseToObject(data);

  const handleChangePage = (_: unknown, newPage: number) => {
    setSearchParams({ page: String(newPage + 1), perPage: perPageParam.toString() });
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (fetchConfig.rowsPerPageOptions.includes(Number(event.target.value))) {
      setSearchParams({ page: '1', perPage: event.target.value });
      return;
    }

    setSearchParams({ page: '1', perPage: '50' });
  };

  return (
    <RecipesTableView
      data={recipesResponse}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
};

export default RecipesTable;
