import { useState } from 'react';
import { Order } from '../../../shared/helpers/get-comparator';

const useTableSort = <T extends { name: string }>() => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof T>('name');

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof T) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return { order, orderBy, handleRequestSort };
};

export default useTableSort;
