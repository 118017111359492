import { HeadCell } from '../../../../types';
import { IngredientHeadCells } from '../../../types';

const ingredientHeadCells: readonly HeadCell<IngredientHeadCells>[] = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'English',
  },
  {
    id: 'nameDe',
    numeric: false,
    disablePadding: false,
    label: 'German',
  },
  {
    id: 'energy',
    numeric: true,
    disablePadding: false,
    label: 'Calories',
  },
  {
    id: 'carbs',
    numeric: true,
    disablePadding: false,
    label: 'Carbs, g',
  },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Protein, g',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: 'Fat, g',
  },
  {
    id: 'recipesCount',
    numeric: true,
    disablePadding: false,
    label: 'Associated recipes',
  },
];

export default ingredientHeadCells;
