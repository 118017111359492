import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { PaginatedResult } from '../../../types';
import { CreateIngredientRequest, Ingredient } from '../../types';

const basePath = Paths.INGREDIENTS;

const createIngredient = async (ingredient: CreateIngredientRequest) => {
  try {
    const response = await koqoonApi.post(basePath, ingredient);
    return response;
  } catch (err: any) {
    throw err.response.data;
  }
};

const getIngredientById = async (ingredientId?: string) => {
  try {
    if (!ingredientId) return;
    const response = await koqoonApi.get(`${basePath}/${ingredientId}`);
    return response.data as Ingredient;
  } catch (err: any) {
    throw err.response.data;
  }
};

const getIngredients = async ({
  query = '',
  page = 1,
  perPage = 1000,
}: {
  query?: string;
  page?: number;
  perPage?: number;
}) => {
  try {
    const response = await koqoonApi.get(basePath, {
      params: {
        query,
        page,
        perPage,
      },
    });

    return response.data as PaginatedResult<Ingredient>;
  } catch (err: any) {
    throw err.response.data;
  }
};

const updateIngredient = async (ingredientId: string, ingredient: CreateIngredientRequest) => {
  try {
    const response = await koqoonApi.put(`${basePath}/${ingredientId}`, ingredient);
    return response;
  } catch (err: any) {
    throw err.response.data;
  }
};

const removeIngredient = async (ingredientId?: string) => {
  if (!ingredientId) return;

  const response = await koqoonApi.delete(`${basePath}/${ingredientId}`);

  return response.data;
};

const removeIngredients = async (ingredientIds?: string[]) => {
  if (!ingredientIds) return;

  try {
    await koqoonApi.delete(`${basePath}/remove-ingredients`, {
      data: {
        ids: ingredientIds,
      },
    });
  } catch (err: any) {
    throw err.response.data;
  }
};

export const ingredientsService = {
  getIngredients,
  createIngredient,
  removeIngredients,
  updateIngredient,
  removeIngredient,
  getIngredientById,
};
