import { Checkbox, Chip, TableBody, TableCell, TableRow } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableCellStyled } from '../../../../components';
import { getComparator, Order, stableSort } from '../../../../shared';
import { PaginatedResult } from '../../../../types';
import { PageableRecipe, RecipeHeadCells } from '../../../types';
import { mapRecipesToCells } from './mappers';

type Props = {
  data: PaginatedResult<PageableRecipe>;
  selected: string[];
  perPage: number;
  order: Order;
  orderBy: keyof RecipeHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, name: string) => void;
};

const RecipesEnhancedTableBody: React.FC<Props> = ({
  data,
  perPage,
  order,
  orderBy,
  handleOnSelect,
  selected,
}) => {
  const { data: recipes } = data;
  const mappedRecipes = mapRecipesToCells(recipes);
  const navigate = useNavigate();
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const rows = useMemo(
    () => stableSort(mappedRecipes, getComparator(order, orderBy)).slice(0, perPage),
    [mappedRecipes, order, orderBy, perPage],
  );

  const hanldeRecipeClick = (id: string) => {
    const recipe = recipes.find((rec) => rec.id === id);

    if (recipe) {
      navigate(`/recipes/details?recipeId=${recipe.id}`);
    }
  };

  return (
    <TableBody>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const isPublished = row.isPublished === 'true' ? 1 : 0;
        const isDone = row.completion === 'Done';
        return (
          <TableRow
            hover
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.id}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
          >
            <TableCell padding='checkbox'>
              <Checkbox
                data-testid='recipe-checkbox'
                onClick={(event) => handleOnSelect(event, row.id)}
                color='primary'
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCell>
            <TableCellStyled active={isPublished} onClick={() => hanldeRecipeClick(row.id)}>
              {row.name}
            </TableCellStyled>
            <TableCellStyled active={isPublished} onClick={() => hanldeRecipeClick(row.id)}>
              {row.categories}
            </TableCellStyled>
            <TableCellStyled active={isPublished} onClick={() => hanldeRecipeClick(row.id)}>
              {row.complexity}
            </TableCellStyled>
            <TableCell align='center' onClick={() => hanldeRecipeClick(row.id)}>
              <Chip
                label={isPublished ? 'Published' : 'Unpublished'}
                sx={{
                  color: isPublished ? 'kqn.white' : 'kqn.darkerGray',
                  borderRadius: '8px',
                  bgcolor: isPublished ? 'kqn.green' : 'kqn.ultraLightGray',
                }}
              />
            </TableCell>
            <TableCell align='center' onClick={() => hanldeRecipeClick(row.id)}>
              <Chip
                label={row.completion}
                sx={{
                  color: isDone ? 'kqn.white' : 'kqn.darkerGray',
                  borderRadius: '8px',
                  bgcolor: isDone ? 'kqn.green' : 'kqn.ultraLightGray',
                }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default RecipesEnhancedTableBody;
