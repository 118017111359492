import { Box, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Order } from '../../shared/helpers/get-comparator';
import { HeadCell } from '../../types';

interface EnhancedTableProps<T> {
  onRequestSort?: (event: React.MouseEvent<unknown>, property: keyof T) => void;
  order?: Order;
  orderBy?: string;
  headCells: readonly HeadCell<T>[];
  children?: React.ReactNode;
  hasCheckbox?: boolean;
}

export function EnhancedTableHead<T>({
  order,
  orderBy,
  onRequestSort,
  headCells,
  children,
  hasCheckbox = true,
}: EnhancedTableProps<T>) {
  const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
    onRequestSort?.(event, property);
  };

  return (
    <TableHead sx={{ bgcolor: 'kqn.cooper', height: '55px' }} data-testid='table-header'>
      {children}
      <TableRow>
        {hasCheckbox && <TableCell padding='checkbox' sx={{ color: '#fff', height: '55px' }} />}
        {headCells.map((headCell) => (
          <TableCell
            colSpan={headCell.colSpan}
            sx={{ color: '#fff', fontSize: '1rem' }}
            key={String(headCell.id)}
            align='center'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component='span' sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
