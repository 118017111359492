import { koqoonApi } from '../../../config';
import { Paths } from '../../../constants';
import { CreateUserRequest, RegisterAccount, Role, UserResponse } from '../../types';

const userPath = Paths.USERS;

async function getUsers(querySearch = '', pageNumber = 1, pageSize = 10) {
  try {
    const response = await koqoonApi.get(userPath, {
      params: { query: querySearch, page: pageNumber, perPage: pageSize },
    });

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function register(data: RegisterAccount) {
  try {
    const response = await koqoonApi.post(`${userPath}/register`, data);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function updateAccount({ data, userId }: { userId?: string; data?: any }) {
  if (!userId || !data) return;

  try {
    const response = await koqoonApi.put(`${userPath}/update-account`, data);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function updateUser({ data, userId }: { userId?: string; data?: any }) {
  if (!userId || !data) return;

  try {
    const response = await koqoonApi.put(`${userPath}/${userId}/update-account`, data);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function createUserAccount(data: CreateUserRequest) {
  try {
    const response = await koqoonApi.post(`${userPath}/create-user-account`, data);

    return response.data as UserResponse;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function deactivateAccounts(data?: string[]) {
  if (!data) return;

  const path = `${userPath}/deactivate-accounts`;
  return koqoonApi
    .patch(path, {
      ids: data,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err.response.data?.message || 'Something went wrong';
    });
}

async function deleteAccount({ userId }: { userId?: string }) {
  if (!userId) return;

  try {
    const response = await koqoonApi.delete(`${userPath}/${userId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function activateAccounts(data: string[]) {
  const path = `${userPath}/activate-accounts`;
  return koqoonApi
    .patch(path, { ids: data })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      throw err.response.data?.message || 'Something went wrong';
    });
}

async function resendEmail(email: string) {
  try {
    const encodedEmail = encodeURIComponent(email);
    const response = await koqoonApi.post(
      `${userPath}/resend-activation-email?email=${encodedEmail}`,
    );

    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function getUserById(userId?: string) {
  if (!userId) return;
  try {
    const response = await koqoonApi.get(`${userPath}/${userId}`);

    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

async function getAllRoles() {
  try {
    const response = await koqoonApi.get(`${userPath}/roles`);

    return response.data as Role[];
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
}

export const userService = {
  getUsers,
  register,
  updateUser,
  getAllRoles,
  updateAccount,
  getUserById,
  resendEmail,
  deleteAccount,
  activateAccounts,
  createUserAccount,
  deactivateAccounts,
};
