import { koqoonApi } from '../../../config';
import { PaginatedResult } from '../../../types';
import { CategoryResponse, CreateCategoryRequest } from '../../types';

const basePath = '/categories';

const getCategoryById = async (categoryId?: string) => {
  if (!categoryId) return;

  try {
    const response = await koqoonApi.get(`${basePath}/${categoryId}`);
    return response.data as CategoryResponse;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const getCategories = async ({
  query = '',
  page = 1,
  perPage = 10,
}: {
  query?: string;
  page?: number;
  perPage?: number;
}) => {
  try {
    const response = await koqoonApi.get(basePath, {
      params: {
        query,
        page,
        perPage,
      },
    });

    return response.data as PaginatedResult<CategoryResponse>;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const createCategory = async (category: CreateCategoryRequest) => {
  try {
    const response = await koqoonApi.post(basePath, category);
    return response;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const updateCategory = async ({
  category,
  categoryId,
}: {
  categoryId: string;
  category: CreateCategoryRequest;
}) => {
  try {
    const response = await koqoonApi.put(`${basePath}/${categoryId}`, category);
    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

const deleteCategory = async (categoryId?: string) => {
  if (!categoryId) return;

  try {
    const response = await koqoonApi.delete(`${basePath}/${categoryId}`);
    return response.data;
  } catch (err: any) {
    throw err.response.data?.message || 'Something went wrong';
  }
};

export const categoriesService = {
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getCategoryById,
};
