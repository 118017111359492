import { Box, Paper } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  MainLayout,
  Navigation,
  TableStyled,
  TableWrapper,
  useTableClick,
  useTableSort,
} from '../../../../components';
import { PaginatedResult } from '../../../../types';
import { PageableRecipe, RecipeHeadCells } from '../../../types';
import { RecipeCardView } from '../recipe-card';
import { RecipesEnhancedTableActions } from './recipes-ehanched-table-actions';
import RecipesEnhancedTable from './recipes-enhanced-table';

interface Props {
  data: PaginatedResult<PageableRecipe>;
  onPageChange: (event: unknown, newPage: number) => void;
  onRowsPerPageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const RecipesTableView: React.FC<Props> = ({ data, onPageChange, onRowsPerPageChange }) => {
  const [view, setView] = useState<'table' | 'grid'>('grid');
  const { data: recipes, meta } = data;
  const { selected, setSelected, handleClick } = useTableClick();
  const { handleRequestSort, order, orderBy } = useTableSort<RecipeHeadCells>();
  const { currentPage, perPage } = meta;
  const page = currentPage > 0 ? currentPage - 1 : 0;

  useEffect(() => {
    setSelected([]);
  }, [recipes, setSelected]);

  return (
    <TableWrapper>
      <TableStyled>
        <Box sx={{ width: '100%' }}>
          <Navigation pageTitle='Overview'>
            <RecipesEnhancedTableActions
              recipe={recipes.filter((recipe) => recipe.id === selected[0])[0]}
              selected={selected}
              handleView={setView}
              selectedView={view}
            />
          </Navigation>
          <MainLayout>
            <Paper sx={{ width: '100%', mb: 2, boxShadow: view === 'grid' ? 'none' : '' }}>
              {view === 'table' ? (
                <RecipesEnhancedTable
                  data={data}
                  onPageChange={onPageChange}
                  onRowsPerPageChange={onRowsPerPageChange}
                  order={order}
                  orderBy={orderBy}
                  page={page}
                  perPage={perPage}
                  selected={selected}
                  handleOnSelect={handleClick}
                  handleRequestSort={handleRequestSort}
                />
              ) : (
                <RecipeCardView />
              )}
            </Paper>
          </MainLayout>
        </Box>
      </TableStyled>
    </TableWrapper>
  );
};

export default RecipesTableView;
