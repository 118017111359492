import { Checkbox, Chip, TableBody, TableCell, TableRow } from '@mui/material';
import React, { useMemo } from 'react';
import { TableCellStyled } from '../../../../components';
import { getComparator, stableSort } from '../../../../shared';
import { PaginatedResult } from '../../../../types';
import { PageableUser, UsersHeadCells } from '../../../types';

type Props = {
  data: PaginatedResult<PageableUser>;
  selected: string[];
  perPage: number;
  order: 'asc' | 'desc';
  orderBy: keyof UsersHeadCells;
  handleOnSelect: (event: React.MouseEvent<unknown, MouseEvent>, name: string) => void;
};

const UserTableBody: React.FC<Props> = ({
  data,
  perPage,
  order,
  orderBy,
  selected,
  handleOnSelect,
}) => {
  const { data: users } = data;
  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  const rows = useMemo(
    () => stableSort(users, getComparator(order, orderBy)).slice(0, perPage),
    [users, order, orderBy, perPage],
  );

  return (
    <TableBody data-testid='table-body'>
      {rows.map((row, index) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const active = row.active === 'active' ? 1 : 0;

        return (
          <TableRow
            hover
            onClick={(event) => handleOnSelect(event, row.id)}
            role='checkbox'
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.email}
            selected={isItemSelected}
            sx={{ cursor: 'pointer' }}
          >
            <TableCellStyled active={active} padding='checkbox'>
              <Checkbox
                color='primary'
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </TableCellStyled>
            <TableCellStyled active={active} component='th' id={labelId} scope='row'>
              {row.name}
            </TableCellStyled>
            <TableCellStyled active={active}>{row.lastName}</TableCellStyled>
            <TableCellStyled active={active}>{row.email}</TableCellStyled>
            <TableCellStyled active={active}>{row.role}</TableCellStyled>
            <TableCell align='center'>
              {row.providers.split(',').map((provider) => {
                if (!provider) return null;
                const isKoqoon = provider.trim() === 'Koqoon';
                return (
                  <Chip
                    key={provider}
                    label={provider}
                    sx={{
                      color: 'kqn.white',
                      borderRadius: '8px',
                      bgcolor: isKoqoon ? 'kqn.cooper' : 'kqn.green',
                      textTransform: isKoqoon ? 'uppercase' : 'none',
                      mr: 1,
                    }}
                  />
                );
              })}
            </TableCell>
            <TableCell align='center'>
              <Chip
                label={active ? 'Active' : 'Inactive'}
                sx={{
                  color: active ? 'kqn.white' : 'kqn.darkerGray',
                  borderRadius: '8px',
                  bgcolor: active ? 'kqn.green' : 'kqn.ultraLightGray',
                }}
              />
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
};

export default UserTableBody;
